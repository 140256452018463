@import './../../../../scss/theme-bootstrap';

.product-benefits {
  .product-quickshop__attributes & {
    @include breakpoint($medium-up) {
      margin: 0 auto 20px auto;
      border-top: 1px solid $color-border;
      padding: 20px 0 0 0;
    }
    h5 {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
        text-transform: uppercase;
      }
    }
    .product-benefits__item {
      max-width: 25%;
    }
  }
  &__list {
    text-align: center;
    @include breakpoint($medium-up) {
      text-align: #{$ldirection};
    }
  }
  &__item {
    display: inline-block;
    text-align: center;
    margin: 0 10px;
    width: 50px;
    vertical-align: top;
    @include breakpoint($medium-up) {
      margin: 0 25px 0 0;
    }
    &-icon {
      display: block;
      max-width: 50px;
      height: 50px;
      margin: auto;
    }
    &-label {
      font-size: 14px;
      line-height: 1.1;
      display: block;
      margin: 5px 0 0 0;
    }
  }
}
